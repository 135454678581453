const ChatVersions =[
    // {version: "VERSION_1",version_id:1, name: "AI Companion V1"},
    // {version: "VERSION_2",version_id:2, name: "AI Companion V2"},
    {version: "5",version_id:5, name: "Codie"},
    {version: "VERSION_3",version_id:3, name: "AI Companion V3"},

]



export default ChatVersions
