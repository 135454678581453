import React, { useState } from "react";
// import LikeIcon from "../../assets/like.jpg";
// import DisLikeIcon from "../../assets/dislike.jpg";
import ChatIcon from "../../assets/chaticon.png";
import { Button, Modal, OverlayTrigger, Popover, Table } from "react-bootstrap";
import {
  // getRecommendationData,
  getReferencesWithPageNumber,
} from "../../api/Service";
import Thumsup from "../../assets/svg/Thumsup";
import ThumbsDown from "../../assets/svg/ThumbsDown";
import { useAuth } from "../../../../../authContext/AuthContext";
import axios from "axios";
import axiosInstance from "../../../../../axiosInterceptor";

const ReferenceModal = ({citationlength, eachLocation, reference }) => {
  const [popoverResponse, setpopoverResponse] = useState({
    content: "",
    page_number: [],
    reference_documents: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showToolTip, setShowToolTip] = useState(false);

  const handleReferenceClick = async (e, reference) => {
    e.preventDefault();
    if (showToolTip) {
      setShowToolTip(false);
      setpopoverResponse(null);
      document.removeEventListener("click", handleOutSideClick);
      return;
    }
    const content = reference?.content
      ? reference?.content
      : reference.reference_text;
    const s3Location = reference.location
      ? reference?.location
      : reference?.source;
    setIsLoading(true);
    setShowToolTip(true);
    try {
      const result = await getReferencesWithPageNumber(content, s3Location);
      const popoverdata = result.pages[0];
      setpopoverResponse(popoverdata);
      //document.addEventListener("click",handleOutSideClick)
    } catch (error) {
      console.log("Error fetching references with page number:", error.message);
    } finally {
      setIsLoading(false);
      document.addEventListener("click", handleOutSideClick);
    }
  };
  const handleNoReferenceClick = (e, reference) => {
    e.preventDefault();
    if (showToolTip) {
      setShowToolTip(false);
      setpopoverResponse(null);
      document.removeEventListener("click", handleOutSideClick);
      return;
    }
    setIsLoading(true);
    setShowToolTip(true);
    document.addEventListener("click", handleOutSideClick);
  };
  const handleOutSideClick = (event) => {
    //const popoverElement=document.querySelector(".popover");
    const sanitizedReferenceId = CSS.escape(`reference-${reference.source}`);
    if (
      !event.target.closest(`#${sanitizedReferenceId}`) &&
      !event.target.closest(".citations-button")
    ) {
      setShowToolTip(false);
      setpopoverResponse(null);
      document.removeEventListener("click", handleOutSideClick);
    }
  };

  const pg_no = popoverResponse?.page_number.join(", ");
  const handledownloadBookClick = (fileName) => {
    setIsLoading(true);
    axiosInstance
      .get(
        `https://z2lqng5zx5.execute-api.ap-southeast-2.amazonaws.com/lambda_genai_ics_v1/?file_name=${fileName}&pg_no=${pg_no}`
      )
      .then((response) => {
        const { url } = response.data;
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        link.setAttribute("target", "_blank");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  
  return (
    <OverlayTrigger
      trigger="click"
      placement="right"
      show={showToolTip}
      overlay={
<Popover id={`reference-${reference?.source}`}>
          <Popover.Body>
            <h6>Context</h6>
            <div className="ml-2">
              {reference?.content
                ? reference?.content
                    .replace(/^:\s*"/, '"')
                    .replace(/[\r\n]+/g, "")
                    .replace(/},\s*{/g, "},{")
                : reference?.reference_text}
            </div>
            <h6>References:</h6>
            {isLoading && !reference?.location ? (
              <h6 className="references-color">Loading ...</h6>
            ) : (
              <div className="references-color">
                {reference?.content ? (
                  eachLocation.map((loc, index) => (
                    <p style={{ marginBottom: "5px" }} key={index}>
                      {"*" + loc.split("/").pop()}
                    </p>
                  ))
                ) : popoverResponse?.page_number.length > 0 ? (
                  <p>{popoverResponse?.reference_documents.join(", ")}</p>
                ) : (
                  <p>References not Found</p>
                )}

                {reference?.content ? (
                  // Check if eachLocation is not empty and has a filename ending with .pdf
                  eachLocation && eachLocation.length > 0 ? (
                    eachLocation.some((location) =>
                      location?.split("/").pop()?.endsWith(".pdf")
                    ) ? (
                      reference?.metadata && reference.metadata.length > 0 ? (
                        <p>Page Number: {reference.metadata}</p>
                      ) : (
                        " "
                      )
                    ) : (
                      " "
                    )
                  ) : (
                    " "
                  )
                ) : popoverResponse?.page_number.length > 0 ? (
                  <p>Page Number: {popoverResponse?.page_number.join(", ")}</p>
                ) : (
                  <p>Page Number not Found</p>
                )}
              </div>
            )}
            <div
              className="hyperlink-v2"
              onClick={() => handledownloadBookClick(reference?.source)}
            >
              {popoverResponse?.page_number.length > 0 && (
                <a className="hyperlink-anchor-v2">{reference?.location}</a>
              )}
            </div>
          </Popover.Body>
        </Popover>
      }
    >
      {reference?.citation_number ? (
        <button
          className="citations-button"
          onClick={(e) => handleReferenceClick(e, reference)}
        >
          {reference?.citation_number}
        </button>
      ) : (
        <button
          className="citations-button"
          onClick={(e) => handleNoReferenceClick(e, reference)}
        >
          {citationlength + 1}
        </button>
      )}
    </OverlayTrigger>
  );
};

export const Version2Message = ({ message }) => {
  //console.log(message, "85::");
  return (
    <>
      {message?.answer ? (
        <>
          {/* Render the main message and references once */}
          <div>
            <p>{message?.answer?.message}</p>
            {message?.answer?.references?.map((item, index) => (
              <ReferenceModal
                key={`item-${index}`}
                citationlength={index}
                eachLocation={message?.answer?.location}
                reference={item}
              />
            ))}
          </div>

          {/* Now loop over the chunks (answer_chunk1, answer_chunk2, etc.) */}
          {Object.keys(message.answer).map((key) => {
            const msz = message.answer[key];

            // Check if the key is a chunk (answer_chunk1, answer_chunk2, etc.)
            if (key.startsWith("answer_chunk")) {
              return (
                <div key={key}>
                  {msz?.answer}
                  {msz?.references?.map((reference, i) => (
                    <ReferenceModal
                      key={i}
                      citationlength={i}
                      reference={reference}
                    />
                  ))}
                </div>
              );
            }

            return null; // If it's not a chunk, don't render anything
          })}
        </>
      ) : null}
    </>
  );
};

const VersionMessage = ({
  message,
  handleLike,
  handleRecommendationClick,
  handleThumbsDown,
}) => {
  const isVersion2 = typeof message.answer === "object";
  return (
    <>
      <p style={{ whiteSpace: "pre-line" }}>
        {isVersion2 ? <Version2Message message={message} /> : message.answer}
      </p>
      <span
        className={`like-dislike-icon ${message.is_liked && "selected"}`}
        onClick={() =>
          handleLike(message.message_id, message.is_liked ? null : true)
        }
      >
        <Thumsup isLiked={message.is_liked} />
      </span>
      <span
        className={`like-dislike-icon ${
          message.is_liked === false && "selected"
        }`}
        onClick={handleThumbsDown}
      >
        <ThumbsDown isLiked={message.is_liked} />
      </span>
      {!isVersion2 && (
        <button
          style={{ float: "right" }}
          className="references-btn-version1"
          onClick={() => handleRecommendationClick(message.contents_list)}
        >
          References
        </button>
      )}
    </>
  );
};

const MessageCard = ({ message, updateLikeState }) => {
  const { userEmail } = useAuth();
  const [openRecommendationModal, setOpenRecommendaionModal] = useState(false);
  const [recommendationContent, setRecommendaionContent] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showDislikeModal, setShowDislikeModal] = useState(false);
  const [dislikeComment, setDislikeComment] = useState("");
  const [codieRecommendation, setCodieRecommendation] = useState([]);
  const covertedFirstLetter = () => {
    if (Array.isArray(userEmail)) {
      return userEmail[0]?.charAt(0).toUpperCase() || "";
    }
    return typeof userEmail === "string"
      ? userEmail.charAt(0).toUpperCase()
      : "";
  };
  const handleLike = (
    message_id,
    is_liked,
    user_comment_on_message_response = ""
  ) => {
    const session_token = sessionStorage.getItem("accessToken");
    updateLikeState({
      session_token,
      message_id,
      is_liked,
      user_comment_on_message_response,
    });
  };
  const handleThumbsDown = () => {
    const addComment = window.confirm("Would you like to add a comment?");
    if (addComment) {
      setShowDislikeModal(true);
    } else {
      handleLike(message.message_id, false, null);
    }
  };

  const handleDislikeSubmit = () => {
    const comment = dislikeComment.trim() === "" ? null : dislikeComment;
    handleLike(message.message_id, false, comment);
    setShowDislikeModal(false);
    setDislikeComment("");
    alert("Feedback submitted succesfully!");
  };

  const handleRecommendationClick = async (contentList) => {
    console.log(contentList, "cons");
    const rr = contentList.map((item) => {
      console.log(item.metadata);
      console.log(!contentList.every((item) => item.metadata));
    });
    console.log(rr);
    if (contentList.every((item) => item.metadata)) {
      console.log("sdfsdfs");
      setOpenRecommendaionModal(true);
      setCodieRecommendation(contentList);
    } else {
      console.log("else");
      try {
        console.log("hehe");
        setIsLoading(true);
        const newList =
          contentList?.map((option) => {
            const s3_location =
            option.s3_location ? option.s3_location.map(
              (val) => val.split("s3://genai-imedx-internal")[1]
            ) ?? [] : [option.filepath];
            return {
              content: option.content,
              s3_location,
            };
          }) ?? [];
        setOpenRecommendaionModal(true);
        const result = await getReferencesWithPageNumber(newList, "", true);
        console.log(result, "checkres");

        if (result.error) {
          throw new Error(result.error);
        }
        if (result?.pages) setRecommendaionContent(result.pages);
        else setRecommendaionContent(result);
        setIsLoading(false);
      } catch (err) {
        console.log("recommendations_error::", err.message);
      }
    }
  };
  const mapRecommendationData = (data) => {
    return data.map((item) => {
      const referenceUri = item?.location?.s3Location?.uri || "";
      const referenceDocument = referenceUri.split("/")[3] || "";
      return {
        content: item.content.text,
        reference_documents: referenceDocument,
        page_number: item.metadata["x-amz-bedrock-kb-document-page-number"],
        reference_link: referenceUri,
      };
    });
  };
  const mappedData = mapRecommendationData(codieRecommendation);
  const messagedata = {
    ...message.answer,
    message_id: message.message_id,
    is_liked: message.is_liked,
  };
  // console.log(messagedata,"messagedata:::");
  const pg_no = Array.isArray(recommendationContent)
    ? recommendationContent
        .map((item) => item.page_number) // Extract the page_number arrays
        .flat() // Flatten the arrays in case there are multiple items
        .filter(Boolean) // Remove any undefined or null values
        .join(",") // Convert to a comma-separated string
    : "";
  const pdfFiles =
    messagedata?.contents_list?.length > 0
      ? messagedata?.contents_list[0]?.s3_location?.filter(
          (file) =>
            file?.endsWith(".pdf")
        )
      : [];
  const uniqueReccommendationContent = Array.isArray(recommendationContent)
    ? recommendationContent.filter(
        (item, index, self) =>
          index ===
          self.findIndex((t) => JSON.stringify(t) === JSON.stringify(item))
      )
    : "";
  const fileNames = pdfFiles && pdfFiles?.map((file) => file.split("/")?.pop());
  //console.log(fileNames,pdfFiles, "214:::");
  const uniqueFileNames = [...new Set(fileNames)];
  const handledownloadBookClick = (fileName) => {
    setIsLoading(true);
    axiosInstance
      .get(
        `https://z2lqng5zx5.execute-api.ap-southeast-2.amazonaws.com/lambda_genai_ics_v1/?file_name=${fileName}&pg_no=${pg_no}`
      )
      .then((response) => {
        const { url } = response.data;
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        link.setAttribute("target", "_blank");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleCodieDownloadBook = (fileName, pageNumber) => {
    setIsLoading(true);
    axiosInstance
      .get(
        `https://p1id01c3r8.execute-api.ap-southeast-2.amazonaws.com/lambda_genai_ics_v2/?file_name=${fileName}&pg_no=${pageNumber}`
      )
      .then((response) => {
        const { url } = response.data;
        if(url){
            // Open the file URL in a new tab
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        link.setAttribute("target", "_blank"); // Ensuring it opens in a new tab
  
        // Append the link, trigger the click, and remove the link afterward
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <>
      {/* Displaying input */}
      <div className="my-input-message">
        <div className="my-input-logo">
          <p>{covertedFirstLetter()}</p>
        </div>
        <div className="my-input-message-data">
          <span>You:</span>
          <p>{message.question}</p>
        </div>
      </div>

      {/* Displaying Response */}
      {message.answer && (
        <div className="my-input-message">
          <div className="my-input-logo">
            <img src={ChatIcon} alt="CompanionLogo" />
          </div>
          <div className="my-input-message-data">
            <span>AI Companion:</span>
            <VersionMessage
              message={messagedata}
              handleLike={handleLike}
              handleRecommendationClick={handleRecommendationClick}
              handleThumbsDown={handleThumbsDown}
            />
          </div>
        </div>
      )}
      {openRecommendationModal && (
        <Modal
          show={openRecommendationModal}
          onHide={() => setOpenRecommendaionModal(false)}
          className="recommendation-modal"
        >
          <Modal.Body>
            {isLoading ? (
              <div className="d-flex justify-content-center align-items-center">
                {/* <Spinner /> */}
                <h6>Loading ...</h6>
              </div>
            ) : (
              <Table striped hover>
                <thead>
                  <tr>
                    <th>Content</th>
                    <th>Reference Documents</th>
                    <th>Page No.</th>
                    <th>Reference Link</th>
                  </tr>
                </thead>
                <tbody>
                  {uniqueReccommendationContent.length > 0 &&
                  Array.isArray(recommendationContent) ? (
                    uniqueReccommendationContent.map((contentdata, i) => (
                      <tr key={i}>
                        <td>{contentdata.content}</td>
                        <td>{contentdata.reference_documents}</td>
                        <td>{contentdata.page_number.join(",")}</td>
                        <td onClick={() => handledownloadBookClick(contentdata)}>{contentdata?.pdf_link ?  <a className="hyperlink-btn-anchor">{contentdata?.pdf_link}</a> : ""}</td>
                      </tr>
                    ))
                  ) : mappedData.length > 0 ? (
                    mappedData.map((contentdata, i) => {
                      const fileName = contentdata.reference_link
                        .split("/")
                        .pop(); // Extract file name
                    const extractedPath = contentdata.reference_link.split("/").slice(3).join("/");
                      const isPdf = contentdata.reference_link.endsWith(".pdf");
                      return (
                        <tr key={i}>
                          <td title={contentdata.content}>
                            {contentdata.content}
                          </td>
                          <td title={contentdata.reference_documents}>
                            {contentdata.reference_documents}
                          </td>
                          <td
                            title={
                              contentdata.page_number ||
                              "Page numbers can only be displayed for PDF files."
                            }
                          >
                            {contentdata.page_number
                              ? contentdata.page_number
                              : "Page numbers can only be displayed for PDF files."}
                          </td>
                          <td>
                            {isPdf ? (
                              <a
                              title={contentdata.reference_link.split('/').pop}
                                href={contentdata.reference_link}
                                rel="noopener noreferrer"
                                onClick={()=>handleCodieDownloadBook(extractedPath,contentdata.page_number)}
                              >
                                {fileName}
                              </a>
                            ) : (
                              <span className="disabled-link-codie">{fileName}</span>
                            )}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="4">No recommendations available</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            )}
            {/* {!isLoading && (
              <div>
                {recommendationContent==="Text not found in the document"?<p style={{marginLeft:'12px'}}>No references</p>:uniqueFileNames?.map((fileName, index) => (
                  <button
                    className="hyperlink-btns"
                    key={index}
                    onClick={() => handledownloadBookClick(fileName)}
                  >
                    <a className="hyperlink-btn-anchor">{fileName}</a>
                  </button>
                ))}
              </div>
            )} */}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setOpenRecommendaionModal(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {/* Dislike Modal */}
      <Modal
        show={showDislikeModal}
        onHide={() => setShowDislikeModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Why did you dislike this answer?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <textarea
            className="form-control"
            rows="4"
            placeholder="Please provide a detailed explanation of why the AI didn't answer as per your expectation."
            value={dislikeComment}
            onChange={(e) => setDislikeComment(e.target.value)}
          ></textarea>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowDislikeModal(false)}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={handleDislikeSubmit}>
            Send Feedback
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MessageCard;
